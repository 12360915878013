import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faCode,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
<FontAwesomeIcon icon="fa-solid fa-users-viewfinder" />;

const Approach = () => {
  return (
    <section
      id="approach"
      className="bg-chocolate"
      aria-labelledby="about-header"
    >
        <div className="max-w-5xl mx-auto p-8">
          <h2
            id="about-header"
            className="text-3xl font-bold mb-6ont-bold text-white"
          >
            Our Approach
          </h2>

          <ul
            className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6"
            aria-label="list of tenets of our approach"
          >
            <li className="bg-white border border-gray-200 rounded-lg shadow p-6 text-center dark:bg-gray-800 dark:border-gray-700">
              <FontAwesomeIcon
                icon={faCode}
                className="text-4xl text-prussian-blue mb-4"
                alt="Icon showing an HTML tag to represent code"
              />
              <h3 className="text-xl font-bold mb-2">
                Quality and Craftsmanship
              </h3>
              <p className="text-gray-700 dark:text-gray-400">
                By embracing test- driven development, a comprehensive
                acceptance process, and even early-phase pair programming, we
                ensure your projects are high-quality and reliable, ultimately
                leading to smoother development processes and faster delivery of
                robust solutions.
              </p>
            </li>
            <li className="bg-white border border-gray-200 rounded-lg shadow p-6 text-center dark:bg-gray-800 dark:border-gray-700">
              <FontAwesomeIcon
                icon={faCogs}
                className="text-4xl text-prussian-blue mb-4"
                alt="Icon showing two cogs to represent processes"
              />
              <h3 className="text-xl font-bold mb-2">Adaptable Processes</h3>
              <p className="text-gray-700 dark:text-gray-400">
                We believe in our processes but are not dogmatic about them,
                customizing our approaches to your unique needs by adapting
                agile principles, and effectively collaborating with, or acting
                as, a product owner. This flexibility ensures that we work
                efficiently with your team, delivering tailored solutions that
                align perfectly with your project requirements and business
                goals.
              </p>
            </li>
            <li className="bg-white border border-gray-200 rounded-lg shadow p-6 text-center dark:bg-gray-800 dark:border-gray-700">
              <FontAwesomeIcon
                icon={faUsersViewfinder}
                className="text-4xl text-prussian-blue mb-4"
                alt="Icon showing a people in a camera viewfinder to represent consumer focused"
              />
              <h3 className="text-xl font-bold mb-2">Consumer-Centric Focus</h3>
              <p className="text-gray-700 dark:text-gray-400">
                Our customer-focused approach leverages extensive industry
                experience to deliver solutions that ensure your consumers’
                needs are met, driving their satisfaction and your long-term
                success. By prioritizing the end consumer, we help you grow your
                business.
              </p>
            </li>
          </ul>
        </div>
    </section>
  );
};

export default Approach;
