/* global dataLayer */
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "G-5Q61LHCYRP", {
  linker: {
    domains: ["keller-solutions.com", "blog.keller-solutions.com"],
  },
});
