import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faFacebook,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import useScrollToSection from "../hooks/useScrollToSection";
import MenuItems from "./MenuItems";

const Footer = () => {
  const scrollToSection = useScrollToSection();

  return (
    <footer
      id="contact"
      aria-label="Page footer and contact information"
      className="bg-prussian-blue text-white py-10 shadow-inner"
    >
      <div className="container mx-auto px-6 flex flex-col items-center">
        <h2 className="text-4xl font-bold mb-8 text-center sm:text-xl">Ready to get started?</h2>

        <div className="flex items-center space-x-8 mb-8">
          <a
            href="mailto:info@kellersolutions.com"
            className="text-gray-400 hover:text-white"
            aria-label="Email Keller Solutions"
          >
            <FontAwesomeIcon icon={faEnvelope} className="w-12 h-12" />
          </a>
          <a
            href="tel:12059606688"
            className="text-gray-400 hover:text-white"
            aria-label="Call Keller Solutions"
          >
            <FontAwesomeIcon icon={faPhone} className="w-12 h-12" />
          </a>
          <a
            href="https://linkedin.com/company/keller-solutions"
            className="text-gray-400 hover:text-white"
            target="_blank"
            aria-label="Visit Keller Solutions on LinkedIn"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} className="w-12 h-12" />
          </a>
          <a
            href="https://facebook.com/KellerSolutions"
            className="text-gray-400 hover:text-white"
            target="_blank"
            aria-label="Visit Keller Solutions on LinkedIn"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} className="w-12 h-12" />
          </a>
          <a
            href="https://x.com/kellersolution"
            className="text-gray-400 hover:text-white"
            target="_blank"
            aria-label="Visit Keller Solutions on LinkedIn"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faXTwitter} className="w-12 h-12" />
          </a>
        </div>

        <div className="flex flex-col items-center">
          <nav className="flex space-x-4 mb-4 flex-wrap justify-center">
            <MenuItems
              scrollToSection={scrollToSection}
              className="opacity-60 font-bold text-white hover:opacity-75"
            />
          </nav>
          <p className="text-center text-sm opacity-60">
            &copy; 2024 Keller Solutions. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
