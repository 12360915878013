import React, { useEffect } from "react";
import backgroundImage from "./assets/images/hero-background.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import useScrollToSection from "../hooks/useScrollToSection";

const Hero = () => {
  const scrollToSection = useScrollToSection();
  useEffect(() => {
    const parallax = () => {
      const scrolled = window.scrollY;
      const parallaxElement = document.getElementById("parallax-bg");
      parallaxElement.style.transform = `translateY(${scrolled * 0.25}px)`;
    };

    window.addEventListener("scroll", parallax);
    return () => window.removeEventListener("scroll", parallax);
  }, []);

  const handleLearnMoreClick = (event) => {
    event.preventDefault(); // Prevent default anchor link behavior
    scrollToSection("services");
  };

  return (
    <section id="hero" aria-label="Featured Content">
      <div
        className="parallax-container relative w-full h-full md:aspect-video"
        style={{ overflow: "hidden" }}
      >
        <div
          id="parallax-bg"
          className="absolute inset-0 bg-cover bg-center -z-10 aspect-video"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
        <div className="bg-chocolate md:bg-opacity-50 fin-shape md:absolute w-full text-white">
          <div className="fin-frame py-4 md:py-0"></div>
          <div className="mx-auto px-6 pb-8 md:pb-0 container">
            <div className="">
              <h1 className="text-md lg:text-xl font-bold mb-3 mt-3 text-white lg:mt-6">
                <span className="sr-only">Keller Solutions: </span>
                Digital Solution Architects and Technical Problem Solvers
              </h1>
              <p className="text-sm md:text-md mb-4">
                <span className="font-bold">Keller Solutions</span> is a digital
                solutions and technology consultancy that{" "}
                <span className="font-bold">solves business problems</span> with
                a disciplined approach, leveraging decades of experience to
                deliver value through high-quality, consumer-centric solutions.
              </p>
              <a
                className="font-bold py-2 px-4 rounded mb-6 md:mb-0"
                href="#services"
                aria-label="scroll to the services section to learn more"
                style={{
                  border: "1px solid white",
                  backgroundColor: "rgba(255,255,255,0.1)",
                }}
                onClick={handleLearnMoreClick}
              >
                Learn More{" "}
                <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
