import { useCallback, useEffect } from "react";

const useScrollToSection = () => {
  const scrollToSection = useCallback((id, offset = 100) => {
    const element = document.getElementById(id);
    
    if (element) {
      
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const offsetPosition = elementRect - bodyRect - offset;
      console.log("offSet" + offsetPosition);

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.substring(1); // Remove the '#' character
      scrollToSection(id);
    }
  }, [scrollToSection]);

  return scrollToSection;
};

export default useScrollToSection;
