import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Work = () => {
  const workData = [
    {
      id: 1,
      title: "Regions Bank",
      image: "/images/regions.png",
    },
    {
      id: 2,
      title: "Little Debbie",
      image: "/images/little-debbie.png",
    },
    {
      id: 3,
      title: "Luminox",
      image: "/images/luminox.png",
    },
    {
      id: 4,
      title: "Emergency Safety Solutions",
      image: "/images/ess.png",
    },
    {
      id: 5,
      title: "Compose an IBM Company",
      image: "/images/compose-ibm.png",
    },
    {
      id: 6,
      title: "Pedigree",
      image: "/images/pedigree.png",
    },
    {
      id: 7,
      title: "Wenger NA",
      image: "/images/wenger.png",
    },
    {
      id: 8,
      title: "Kinetic by Windstream",
      image: "/images/kinetic-windstream.png",
    },
    {
      id: 9,
      title: "Majestic Homes",
      image: "/images/majestic-homes.png",
    },
    {
      id: 10,
      title: "AccomplishMint",
      image: "/images/accomplishmint.png",
    },
  ];

  const createCarouselItemImage = (workItem) => (
    <div key={workItem.id}>
      <h3 className="sr-only">{workItem.title}</h3>
      <img src={workItem.image} alt={workItem.title} className="carousel-image" />
    </div>
  );

  return (
    <section
      id="work"
      className="w-full relative"
      aria-labelledby="work-header"
    >
      <div className="w-full p-8">
        <h2 id="work-header" className="text-3xl font-bold mb-6">
          Our Work
        </h2>
        <Carousel
          centerMode
          centerSlidePercentage={66}
          useKeyboardArrows
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          ariaLabel="carousel of companies we have done work for"
          className="max-h-carousel"
        >
          {workData.map(createCarouselItemImage)}
        </Carousel>
      </div>
    </section>
  );
};

export default Work;
