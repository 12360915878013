import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faFacebook, faXTwitter } from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  const [activeTab, setActiveTab] = useState("form");

  useEffect(() => {
    document.title = 'Contact Us - Keller Solutions';
    
    const handleHashChange = () => {
      if (window.location.hash === "#meeting") {
        setActiveTab("meetings");
      } else {
        setActiveTab("form");
      }
    };

    // Check initial hash
    handleHashChange();

    // Add hash change event listener
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  useEffect(() => {
    if (activeTab === "meetings") {
      // Load HubSpot Meetings script
      const meetingsScript = document.createElement("script");
      meetingsScript.src =
        "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
      meetingsScript.async = true;
      document.body.appendChild(meetingsScript);

      // Clean up the script when the tab changes
      return () => {
        document.body.removeChild(meetingsScript);
      };
    } else if (activeTab === "form") {
      // Load HubSpot Forms script and initialize the form
      const formsScript = document.createElement("script");
      formsScript.src = "//js.hsforms.net/forms/embed/v2.js";
      formsScript.async = true;
      formsScript.onload = () => {
        if (window.hbspt && !document.getElementById("hubspot-form-embed")) {
          window.hbspt.forms.create({
            target: "#hubspot-form",
            region: "na1",
            portalId: "46602079",
            formId: "fd62dacd-e173-44f5-882a-11712d31d447",
          });
        }
      };
      document.body.appendChild(formsScript);

      // Clean up the script when the tab changes
      return () => {
        document.body.removeChild(formsScript);
      };
    }
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "meetings") {
      window.location.hash = "#meeting";
    } else {
      window.location.hash = "#form";
    }
  };

  return (
    <section
      id="Contact"
      className="container mx-auto px-6 py-10"
      aria-labelledby="contact-header"
    >
      <div className="max-w-5xl mx-auto py-5">
        <h2 id="contact-header" className="text-3xl font-bold mb-6">
          Contact Us
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-chocolate text-white p-6 my-6">
          <div className="w-full md:w-1/3 px-3 mb-6 flex flex-col">
            <h3 className="text-xl font-bold mb-3">Reach Out Directly</h3>
            <ul>
              <li>
                <a href="mailto:info@keller-solutions.com">
                  <FontAwesomeIcon icon={faEnvelope} className="w-4 h-4" />{" "}
                  info@keller-solutions.com
                </a>
              </li>
              <li>
                <a href="tel:12059606688">
                  <FontAwesomeIcon icon={faPhone} className="w-4 h-4" /> (205)
                  960-6688
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 flex flex-col">
            <h3 className="text-xl font-bold mb-3">Connect on Social Media</h3>
            <ul>
              <li>
                <a href="https://www.linkedin.com/company/keller-solutions/">
                  <FontAwesomeIcon icon={faLinkedin} className="w-4 h-4" />{" "}
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/KellerSolutions">
                  <FontAwesomeIcon icon={faFacebook} className="w-4 h-4" />{" "}
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://x.com/kellersolution">
                  <FontAwesomeIcon icon={faXTwitter} className="w-4 h-4" />{" "}
                  @kellersolution
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex justify-center mb-4 py-5">
          <button
            className={`px-4 py-2 mx-2 ${
              activeTab === "form" ? "bg-gray-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => handleTabClick("form")}
          >
            Get More Information
          </button>
          <button
            className={`px-4 py-2 mx-2 ${
              activeTab === "meetings"
                ? "bg-gray-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => handleTabClick("meetings")}
          >
            Book a Meeting
          </button>
        </div>

        <div className="flex flex-wrap -mx-3">
          {activeTab === "meetings" && (
            <div className="w-full px-3 mb-25">
              <h3 className="text-xl font-bold mb-4">
                Ready to discuss your project in detail?
              </h3>
              <p className="mb-6">
                Schedule a meeting with us at your convenience. We look forward
                to learning more about your needs and exploring how we can
                collaborate.
              </p>

              <div
                className="meetings-iframe-container w-full"
                data-src="https://meetings.hubspot.com/evan-keller?embed=true"
              ></div>
            </div>
          )}
          {activeTab === "form" && (
            <div className="w-full px-3">
              <div id="hubspot-form"></div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
