import React, { useState } from "react";
import Logo from "./assets/images/logos/logo-horizontal.png";
import useScrollToSection from "../hooks/useScrollToSection";
import MenuItems from "./MenuItems";
import { useLocation } from "react-router-dom";

const Header = () => {
  const scrollToSection = useScrollToSection();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const logoClick = (event, section) => {
    event.preventDefault(); // Prevent default anchor link behavior
    if (location.pathname === "/contact") {
      // Navigate to the home page with the hash
      window.location.href = `/`;
    } else {
      // Scroll to the section
      scrollToSection(section);
    }
  };

  return (
    <section id="home">
    <header
      id="masthead"
      aria-label="Page Masthead"
      className="fixed top-0 w-full bg-white shadow-md z-50"
    >
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        <div className="text-2xl font-bold">
          <a
            href={`/`}
            aria-label={`Home Page`}
            onClick={(event) => logoClick(event, 'home')}
          >
            <img src={Logo} alt="Keller Solutions Logo" className="h-20" />
          </a>
        </div>
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-600 focus:outline-none"
            title="Toggle Menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
        <nav
          className="hidden md:flex space-x-4"
          aria-label="jump link navigation for single-page site"
        >
          <MenuItems
            scrollToSection={scrollToSection}
            className="hover:text-gray-900"
          />
        </nav>
        {isOpen && (
          <nav className="md:hidden absolute top-full left-0 right-0 bg-white shadow-md z-50">
            <MenuItems
              scrollToSection={scrollToSection}
              isMobile={true}
              toggleMenu={toggleMenu}
            />
          </nav>
        )}
      </div>
    </header>
    </section>
  );
};

export default Header;
