import React from "react";
import Matt from "./assets/images/matt-headshot.jpg";
import Evan from "./assets/images/evan-headshot.jpg";

const Team = () => {
  const members = [
    {
      photo: Evan,
      name: "Evan Keller",
      title: "Founder",
      description: [
        "Evan, with nearly 30 years in software engineering, has led award-winning projects for top corporations in petroleum, retail, and healthcare. Now as founder of Keller Solutions, he is solidifying his reputation as a tenacious problem solver and champion of the engineering process.",
      ],
    },
    {
      photo: Matt,
      name: "Matthew McCay",
      title: "Solution Architect, Partner",
      description: [
        "Matthew has 20 years of experience in Rails and React engineering, database design, and user experience. He helped grow Compose (formerly MongoHQ) from startup to IBM acquisition. Partnering with Evan, Matt aims to leverage their combined experience across various industries to solve problems for clients.",
      ],
    },
  ];

  return (
    <section
      id="team"
      className="container mx-auto px-6"
      aria-labelledby="team-header"
    >
      <div className="max-w-5xl mx-auto p-8">
        <h2 id="team-header" className="text-3xl font-bold mb-6ont-bold">
          Core Team
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 mb-6">
          {members.map((member, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center bg-white"
            >
              <img
                src={member.photo}
                alt={`${member.name} headshot`}
                className="w-48 h-48 rounded-full object-cover mb-4"
              />
              <div>
                <h3 className="text-xl font-semibold mb-1 text-gray-900 dark:text-white">
                  {member.name}
                </h3>
                <p className="text-lg font-medium text-gray-600 mb-2 dark:text-gray-300">
                  {member.title}
                </p>
                <div className="text-base text-left text-gray-700 dark:text-gray-400">
                  {member.description.map((paragraph, i) => (
                    <p key={i} className="mb-2">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
