import React from "react";
import { useLocation } from "react-router-dom";

const MenuItems = ({ scrollToSection, isMobile, toggleMenu, className }) => {
  const location = useLocation();
  const handleClick = (event, section, link) => {
    event.preventDefault(); // Prevent default anchor link behavior

    if (link) {
      // Navigate to the provided link
      window.location.href = link;
    } else {
      if (location.pathname === "/contact") {
        // Navigate to the home page with the hash
        window.location.href = `/#${section}`;
      } else {
        // Scroll to the section
        scrollToSection(section);
      }
    }
    if (isMobile && toggleMenu) {
      toggleMenu();
    }
  };

  const menuItems = [
    { section: "services", label: "Services" },
    { section: "approach", label: "Approach" },
    { section: "work", label: "Work" },
    { section: "team", label: "Core Team" },
    { section: "blog", label: "Blog", link: "http://blog.keller-solutions.com" },
    { section: "contact", label: "Contact", link: "/contact" },
  ];

  return (
    <>
      {menuItems.map((item) => (
        <a
          key={item.section}
          href={location.pathname === "/contact" ? `/#${item.section}` : item.link || `#${item.section}`}
          aria-label={`Go to ${item.label}`}
          onClick={(event) => handleClick(event, item.section, item.link)}
          className={`${className} ${
            isMobile
              ? "block w-full text-left px-4 py-2"
              : "text-gray-600 focus:outline-none"
          }`}
        >
          {item.label}
        </a>
      ))}
    </>
  );
};

export default MenuItems;
