import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Approach from "./components/Approach";
import Services from "./components/Service";
import Work from "./components/Work";
import Team from "./components/Team";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <RouteChangeTracker />
        <Routes>
          <Route
            path="/"
            element={
              <main className="space-y-16 pt-24" id="main">
                <Hero />
                <Services />
                <Approach />
                <Work />
                <Team />
              </main>
            }
          />
          <Route
            path="/contact"
            element={
              <main className="space-y-16 pt-16">
                <Contact />
              </main>
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (window.gtag) {
        window.gtag("config", "G-5Q61LHCYRP", {
          page_path: url,
        });
      }
      if (window.fbq) {
        window.fbq("track", "PageView");
      }
    };

    handleRouteChange(location.pathname);
  }, [location]);

  return null;
};

export default App;
