import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptopCode,
  faChartLine,
  faShoppingCart,
  faEdit,
  faArrowRightArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

const services = [
  {
    icon: faLaptopCode,
    title: "Full-Stack Web Application Development",
    description:
      "Need a dynamic web application that stands out? Keller Solutions creates Full-Stack Web Applications with a specialization in Ruby on Rails and React. Regardless of your tech stack, leverage our expertise to build mobile-first applications tailored to your needs, ensuring efficiency and cost-effectiveness. Experience the transformative impact of our problem-solving approach as we turn your ideas into robust, user-friendly web applications that drive your business forward.",
  },
  {
    icon: faChartLine,
    title: "Digital Marketing",
    description:
      "Struggling to meet your KPIs? At Keller Solutions, our Digital Marketing services are designed to define and achieve your specific goals. Starting with a solid foundation, we help you select and integration the right tools and simplify the complexities of marketing technology. We optimize each step of the way with a disciplined approach to A/B testing and elevate your digital marketing with our data-driven, customized solutions that align with your business objectives.",
  },
  {
    icon: faShoppingCart,
    title: "E-Commerce Solutions",
    description:
      "Want to boost your online sales? Keller Solutions crafts E-Commerce Solutions with platforms like Shopify to create secure, user-friendly experiences that enhance customer satisfaction and drive revenue. We work closely with clients to bring their vision to life through customizable, scalable solutions. Our stringent quality assurance and security protocols ensure reliable performance. Transform your e-commerce presence with our tailored solutions that support your growth and business goals.",
  },
  {
    icon: faEdit,
    title: "Content Management Systems",
    description:
      "Need efficient content management? Keller Solutions offers versatile CMS solutions with systems like Sitecore, Drupal, and WordPress. We empower businesses to manage digital content seamlessly, integrating third-party tools and ensuring intuitive management. Our focus on customization, scalability, and security guarantees reliable CMS implementations. Enhance your content management with our expertise, supporting your business needs with tailored, secure solutions.",
  },
  {
    icon: faArrowRightArrowLeft,
    title: "API Development and Integration",
    description:
      "Need better data sharing between your systems? Keller Solutions specializes in API Development and Integration to streamline your operations. Our custom, scalable API solutions enable seamless data exchange and effective application integration. We collaborate closely with clients, prioritizing quality assurance and security to ensure robust, reliable APIs. Enhance your system connectivity with our custom APIs, designed to support your business objectives and improve efficiency.",
  },
];

const Services = () => {
  return (
    <section id="services" aria-labelledby="services-header">
      <div className="max-w-5xl mx-auto p-8">
        <h2 className="text-4xl font-bold text-center mb-8" id="services-header">
          Our Services
        </h2>
        <ul
          className="max-w-5xl mx-auto flex flex-col items-center p-6 py-6"
          aria-label="List of services offered"
        >
          {services.map((service, index) => (
            <li
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 my-2 flex flex-col md:flex-row items-center md:items-start w-full max-w-4xl transform transition-transform hover:-translate-y-2"
            >
              <div className="text-prussian-blue mb-4 md:mb-0 text-4xl md:p-8">
                <FontAwesomeIcon icon={service.icon} />
              </div>
              <div className="flex flex-col">
                <h3 className="text-xl font-bold mb-2">{service.title}</h3>
                <p className="text-gray-700">{service.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Services;
